<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-center pt-1">{{ registration_number }} - {{ manufacture }} - {{ variety }}</h4>
        </div>
      </div>
    </b-card>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      ref="wizard"
      :enable-finish-button="true"
      layout="vertical"
      finish-button-text="Frissítés"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="update"
      tabindex="3"
    >

      <!-- account datails tab -->
      <tab-content title="Autó adatai">
        <div class="row">
          <h5 class="col-lg-6 mb-0 pb-0">Forgalmi engedély adatai</h5>
          <div class="col-lg-6 text-right"><button @click="update" class="btn btn-primary">Frissítés</button></div>
          <b-form-group
            label="Rendszám (A)"
            label-for="registration_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="registration_number"
              v-model="registration_number"
              type="text"
              placeholder="Rendszáma"
            />
          </b-form-group>
          <b-form-group
            label="Forgalomba helyezés ideje (B)"
            label-for="entry_into_service"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="entry_into_service"
              v-model="entry_into_service"
              type="date" max="9999-12-31"
              placeholder="Forgalomba helyezés ideje"
            />
          </b-form-group>
          <b-form-group
            label="Gyártás éve"
            label-for="production_year"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="production_year"
              v-model="production_year"
              type="year"
              placeholder="Gyártás éve"
            />
          </b-form-group>
          <b-form-group
            label="Gyártó (D.1)"
            label-for="manufacture"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="manufacture"
              v-model="manufacture"
              type="text"
              placeholder="Gyártó"
            />
          </b-form-group>
          <b-form-group
            label="Model (D.3)"
            label-for="variety"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="variety"
              v-model="variety"
              type="text"
              placeholder="Model"
            />
          </b-form-group>
          <b-form-group
            label="Alvázszám (E)"
            label-for="type"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="type"
              v-model="type"
              type="text"
              placeholder="Alvázszám (E)"
            />
          </b-form-group>
          <b-form-group
            label="Hengerűrtartalom (P.1)"
            label-for="cylinder_capacity"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="cylinder_capacity"
              v-model="cylinder_capacity"
              type="text"
              placeholder="Hengerűrtartalom"
            />
          </b-form-group>
          <b-form-group
            label="Teljesítmény (P.2)"
            label-for="power"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="power"
              v-model="power"
              type="text"
              placeholder="Teljesítmény"
            />
          </b-form-group>
          <b-form-group
            label="Üzemanyag (P.3)"
            label-for="fuel"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="fuel"
              v-model="fuel"
              type="text"
              placeholder="Üzemanyag"
            />
          </b-form-group>
          <b-form-group
            label="Motorszám (P.5)"
            label-for="engine_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="engine_number"
              v-model="engine_number"
              type="text"
              placeholder="Motorszám"
            />
          </b-form-group>
          <b-form-group
            label="Saját tömeg (G)"
            label-for="own_weight"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="own_weight"
              v-model="own_weight"
              type="text"
              placeholder="Saját tömeg"
            />
          </b-form-group>
          <b-form-group
            label="Össz tömeg (F.1)"
            label-for="total_mass"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="total_mass"
              v-model="total_mass"
              type="text"
              placeholder="Össz tömeg"
            />
          </b-form-group>
          <b-form-group
            label="Szín (R)"
            label-for="color"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="color"
              v-model="color"
              type="text"
              placeholder="Szín"
            />
          </b-form-group>
          <b-form-group
            label="Ülések száma (S.1)"
            label-for="seats"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="seats"
              v-model="seats"
              type="number"
              placeholder="Ülések"
            />
          </b-form-group>
          <b-form-group
            label="Környezet védelmi besorolás (V.9)"
            label-for="environmental_classification"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="environmental_classification"
              v-model="environmental_classification"
              type="text"
              placeholder="Környezet védelmi besorolás"
            />
          </b-form-group>
          <b-form-group
            label="Műszaki vizsga lejárata"
            label-for="technical_validity"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="technical_validity"
              v-model="technical_validity"
              type="date" max="9999-12-31"
              placeholder="Műszaki vizsga lejárata"
            />
          </b-form-group>
          <b-form-group
            label="Melyik céghez tartozik"
            label-for="monolit"
            class="col-md-3 mt-1"
          >
            <b-form-select
              id="monolit"
              v-model="monolit"
              :options="monolit_options"
              type="text"
              placeholder="Melyik céghez tartozik"
            />
          </b-form-group>
          <b-form-group
            label="Sofőr"
            label-for="driver"
            class="col-md-3 mt-1"
          >
            <b-form-select
              id="driver"
              v-model="driver"
              type="text"
              :options="employees"
              placeholder="Sofőr"
              text-field="name"
              value-field="name"
            />
          </b-form-group>
        </div>

      </tab-content>
      <tab-content title="Biztosítások">
        <div class="row">
          <h5 class="col-lg-6 mb-2 pb-0">Biztosítások</h5>
          <div class="col-lg-6 text-right"><button @click="update" class="btn btn-primary">Frissítés</button></div>
          <div class="col-lg-12 mt-2 mb-5">
            <div class="row">
              <hr class="col-lg-12">
              <div class="col-lg-12">
                <p>Létrehozás</p>
              </div>
              <div class="col-lg-2">
                <small>Biztosító neve</small>
                <b-form-input
                  id="insurance_name"
                  v-model="insurance_name"
                  type="text"
                  placeholder="Biztosító neve"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás típusa</small>
                <b-form-select
                  id="insurance_type"
                  v-model="insurance_type"
                  placeholder="Biztosító típusa"
                  :options="['Kötelező gépjármű biztosítás', 'Casco']"
                />
              </div>
              <div class="col-lg-2">
                <small>Fizetés gyakorisága</small>
                <b-form-select
                  id="insurance_frequency"
                  v-model="insurance_frequency"
                  placeholder="Biztosító typusa"
                  :options="['Havi', 'Éves', 'Negyedéves']"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás kötvényszáma</small>
                <b-form-input
                  id="policy_number"
                  v-model="policy_number"
                  type="text"
                  placeholder="Biztosítás kötvényszáma"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás ára</small>
                <b-form-input
                  id="'price"
                  v-model="price"
                  type="number"
                  placeholder="Biztosítás ára"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás lejárata</small>
                <b-form-input
                  id="expiry_date"
                  v-model="expiry_date"
                  type="date" max="9999-12-31"
                  placeholder="Biztosítás lejárata"
                />
              </div>
              <div class="col-lg-12">
                <b-button
                  @click="addInsurance"
                  class="w-100 mt-2 btn btn-primary"
                >
                  Létrehozás
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <hr>
          </div>
          <div class="col-lg-12 mt-2" v-for="(insur, index) in insurance" :key="index">
            <div class="row">
              <div class="col-lg-2 text-center">
                <span class="badge badge-danger mt-2" v-if="today_date>insur.expiry_date">Lejárt</span>
                <span class="badge badge-success mt-2" v-else>Érvényes</span>
              </div>
              <div class="col-lg-2">
                <small>Biztosító neve</small>
                <b-form-input
                  :id="'insurance_name_'+index"
                  v-model="insur.insurance_name"
                  type="text"
                  placeholder="Biztosító neve"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás típusa</small>
                <b-form-select
                  :id="'insurance_type_'+index"
                  v-model="insur.insurance_type"
                  :options="['Kötelező gépjármű biztosítás', 'Casco']"
                  placeholder="Biztosítás típusa"
                />
              </div>
              <div class="col-lg-2">
                <small>Fizetés gyakorisága</small>
                <b-form-select
                  id="insurance_frequency"
                  v-model="insur.insurance_frequency"
                  placeholder="Biztosító típusa"
                  :options="['Havi', 'Éves', 'Negyedéves']"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás kötvényszáma</small>
                <b-form-input
                  :id="'policy_number_'+index"
                  v-model="insur.policy_number"
                  type="text"
                  placeholder="Biztosítás kötvényszáma"
                />
              </div>
              <div class="col-lg-1 mt-2">
                <b-button
                  variant="danger"
                  class="btn btn-primary w-100"
                  @click="updateInsurance(index)"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-1 mt-2">
                <b-button
                  class="btn btn-danger w-100"
                  @click="removeInsurance(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-2">
                <small>Biztosítás lejárata</small>
                <b-form-input
                  :id="'expiry_date_'+index"
                  v-model="insur.expiry_date"
                  type="date" max="9999-12-31"
                  placeholder="Biztosítás lejárata"
                />
              </div>
              <div class="col-lg-2">
                <small>Biztosítás ára</small>
                <b-form-input
                  :id="'price_'+index"
                  v-model="insur.price"
                  type="number"
                  placeholder="Biztosítás ára"
                />
              </div>
            </div>
            <hr class="mt-3">
          </div>
        </div>
      </tab-content>
      <tab-content title="Autópálya matrica">
        <div class="row mb-5">
          <h5 class="col-lg-6 mb-2 pb-0">Autópálya matrica</h5>
          <div class="col-lg-6 text-right"><button @click="update" class="btn btn-primary">Frissítés</button></div>
          <div class="col-lg-12 mt-2 mb-5">
            <div class="row">
              <hr class="col-lg-12">
              <div class="col-lg-12">
                <p>Létrehozás</p>
              </div>
              <div class="col-lg-2">
                <small>Vásárlás dátuma</small>
                <b-form-input
                  id="highway_sticker_buy_date"
                  v-model="highway_sticker_buy_date"
                  type="datetime-local"
                  max="9999-12-31T23:59:59"
                  placeholder="Vásárlás dátuma"
                />
              </div>
              <div class="col-lg-2">
                <small>Lejárati dátum</small>
                <b-form-input
                  id="highway_sticker_expiration_date"
                  v-model="highway_sticker_expiration_date"
                  type="date" max="9999-12-31"
                  placeholder="Lejárati dátum"
                />
              </div>
              <div class="col-lg-2">
                <small>Típus</small>
                <b-form-select
                  id="highway_sticker_type"
                  v-model="highway_sticker_type"
                  placeholder="Típus"
                  :options="[ 'D1', 'D1M', 'D2', 'B2', 'U' ]"
                />
              </div>
              <div class="col-lg-2">
                <small>Ára (darabja)</small>
                <b-form-input
                  id="highway_sticker_price"
                  v-model="highway_sticker_price"
                  type="number"
                  placeholder="Autópálya matrica ára"
                />
              </div>
              <div class="col-lg-2">
                <small>Autópálya matrica kategória</small>
                <b-form-select
                  id="highway_sticker_category"
                  v-model="highway_sticker_category"
                  :options="['10 napos', 'Havi', 'Éves országos', 'Éves megyei']"
                  placeholder="Autópálya matrica kategória"
                />
              </div>
              <div class="col-lg-2">
                <br>
                <b-button
                  @click="addHighSticker"
                  class="w-100 btn btn-primary"
                >
                  Létrehozás
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
              <div class="row col-lg-11 justify-content-end">
                <div class="col-lg-3 mt-2" v-if="highway_sticker_category == 'Éves megyei'">
                  <small>Megyék megadása</small>
                  <b-form-select
                    multiple
                    id="highway_sticker_county"
                    v-model="highway_sticker_county"
                    :options="countyList"
                    placeholder="Autópálya matrica kategória"
                  />
                  <small>Ctrl + katt több kijelöléséhez</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <hr>
          </div>
          <div class="col-lg-12 mt-2" v-for="(sticker, index) in highway_sticker" :key="index">
            <div class="row">
              <div class="col-lg-1">
                <br>
                <p style="margin-top:6px;" v-if="today_date > sticker.expiration_date" class="mb-0 text-center"><span style="width:100px" class="badge badge-danger">Lejárt</span></p>
                <p style="margin-top:6px" v-else class="mb-0 text-center"><span style="width:100px" class="badge badge-success">Érvényes</span></p>
              </div>
              <div class="col-lg-2">
                <small>Vásárlás dátuma</small>
                <b-form-input
                  :id="'highway_sticker_buy_date_'+index"
                  v-model="sticker.buy_date"
                  type="datetime-local"
                  max="9999-12-31T23:59:59"
                  placeholder="Vásárlás dátuma"
                />
              </div>
              <div class="col-lg-2">
                <small>Lejárati dátum</small>
                <b-form-input
                  :id="'highway_sticker_expiration_date_'+index"
                  v-model="sticker.expiration_date"
                  type="date" max="9999-12-31"
                  placeholder="Lejárati dátum"
                />
              </div>
              <div class="col-lg-1">
                <small>Típus</small>
                <b-form-select
                  :id="'highway_sticker_type_'+index"
                  v-model="sticker.type"
                  placeholder="Típus"
                  :options="['D1', 'D1M', 'D2', 'B2', 'U']"
                />
              </div>
              <div class="col-lg-2">
                <small>Ára</small>
                <b-form-input
                  :id="'highway_sticker_price_'+index"
                  v-model="sticker.price"
                  type="text"
                  placeholder="Ára"
                />
              </div>
              <div class="col-lg-2">
                <small>Autópálya matrica kategória</small>
                <b-form-select
                  :id="'highway_sticker_category_'+index"
                  v-model="sticker.category"
                  :options="['10 napos', 'Havi', 'Éves országos', 'Éves megyei']"
                  placeholder="Autópálya matrica kategória"
                />
              </div>
              <div class="col-lg-2">
                <br>
                <b-button
                  variant="danger"
                  class="w-50 btn btn-primary"
                  @click="updateHighwaySticker(index)"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                </b-button>
                <b-button
                  class="w-50 btn btn-danger"
                  @click="removeHighwaySticker(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </div>
              <div class="row col-lg-11 justify-content-end">
                <div class="col-lg-3 mt-2" v-if="sticker.category == 'Éves megyei'">
                  <small>Megyék megadása</small>
                  <b-form-select
                    :id="'highway_sticker_county_'+index"
                    v-model="sticker.county"
                    :options="countyList"
                    placeholder="Autópálya matrica kategória"
                  />
                  <small>Ctrl + katt több kijelöléséhez</small>
                </div>
              </div>
            </div>
            <hr class="mt-3"/>
          </div>
        </div>
      </tab-content>
      <tab-content title="Szervíz">
        <div class="row mb-5">
          <h5 class="col-lg-6 mb-2 pb-0">Szervíz</h5>
          <div class="col-lg-6 text-right"><button @click="update" class="btn btn-primary">Frissítés</button></div>
          <div class="col-lg-12 mt-2 mb-5">
            <div class="row">
              <hr class="col-lg-12">
              <div class="col-lg-12">
                <p>Létrehozás</p>
              </div>
              <div class="col-lg-2">
                <small>Szervíz neve</small>
                <b-form-input
                  id="service_service_name"
                  v-model="service_service_name"
                  type="text"
                  placeholder="Szervíz neve"
                />
              </div>
              <div class="col-lg-2">
                <small>Számla sorszám</small>
                <b-form-input
                  id="service_billing_number"
                  v-model="service_billing_number"
                  type="text"
                  placeholder="Számla sorszám"
                />
              </div>
              <div class="col-lg-2">
                <small>Teljesítés dátuma</small>
                <b-form-input
                  id="service_date_of_completion"
                  v-model="service_date_of_completion"
                  type="date" max="9999-12-31"
                  placeholder="Teljesítés dátuma"
                />
              </div>
              <div class="col-lg-2">
                <small>Km állás</small>
                <b-form-input
                  id="service_km_at_the_time_of_service"
                  v-model="service_km_at_the_time_of_service"
                  type="text"
                  placeholder="Km állás"
                />
              </div>
              <div class="col-lg-2">
                <small>Szervíz intervallum</small>
                <b-form-select
                  id="service_service_interval"
                  v-model="service_service_interval"
                  :options="['15 000', '30 000']"
                  placeholder="Szervíz intervallum"
                />
              </div>
              <div class="col-lg-2">
                <br>
                <b-button
                  @click="addService"
                  class="btn btn-primary"
                >
                  Létrehozás
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-3 mt-2">
                <p>Általános szolgáltatások</p>
                <p v-for="(service, index) in servicesOptions" :key="index">
                  <span @click="addToServices(service)" class="badge badge-primary" style="cursor:pointer">
                    {{ service }}
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </span>
                </p>
                <small>Egyedi rögzítése</small>
                <b-form-input
                  id="service_name_of_service_uniq"
                  v-model="service_name_of_service_uniq"
                  type="text"
                  placeholder="Szolgáltatás neve"
                />
                <button class="btn btn-primary w-100 text-center mt-2" @click="addToServicesUniq()">Rögzítés</button>
              </div>
              <div class="col-lg-3 mt-2">
                <p>Igénybe vett szolgáltatások</p>
                <p v-for="(service, index) in service_name_of_service" :key="index">
                  <span style="cursor:pointer" class="badge badge-primary" @click="removeService(index)">
                    {{ service }}
                    <feather-icon
                      icon="MinusIcon"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <hr>
          </div>
          <div class="col-lg-12 mt-2" v-for="(service, index) in services" :key="index">
            <div class="row">
              <div class="col-lg-2">
                <small>Szervíz neve</small>
                <b-form-input
                  :id="'service_name_'+index"
                  v-model="service.service_name"
                  type="text"
                  placeholder="Szervíz neve"
                />
              </div>
              <div class="col-lg-2">
                <small>Számla sorszám</small>
                <b-form-input
                  :id="'service_billing_number_'+index"
                  v-model="service.billing_number"
                  type="text"
                  placeholder="Számla sorszám"
                />
              </div>
              <div class="col-lg-2">
                <small>Teljesítés dátuma</small>
                <b-form-input
                  :id="'date_of_completion_'+index"
                  v-model="service.date_of_completion"
                  placeholder="Teljesítés dátuma"
                  type="date" max="9999-12-31"
                />
              </div>
              <div class="col-lg-2">
                <small>Km állás</small>
                <b-form-input
                  :id="'km_at_the_time_of_service_'+index"
                  v-model="service.km_at_the_time_of_service"
                  type="text"
                  placeholder="Km állás"
                />
              </div>
              <div class="col-lg-2">
                <small>Szervíz intervallum</small>
                <b-form-select
                  id="service_service_interval"
                  v-model="service.service_interval"
                  :options="['15 000', '30 000']"
                  placeholder="Szervízintervallum"
                />
              </div>
              <div class="col-lg-2">
                <br>
                <b-button
                  variant="danger"
                  class="w-50 btn btn-primary"
                  @click="updateServiceFull(index)"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                </b-button>
                <b-button
                  class="w-50 btn btn-danger"
                  @click="removeServiceFull(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-12 mt-0">
                <p class="mb-0">Szolgáltatás megnevezések</p>
                <span class="mr-2 badge badge-primary" v-for="name in service.name_of_service" :key="name">{{ name }}</span>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="Autó gumi">
        <div class="row mb-5">
          <h5 class="col-lg-6 mb-2 pb-0">Autó gumi</h5>
          <div class="col-lg-6 text-right"><button @click="update" class="btn btn-primary">Frissítés</button></div>
          <div class="col-lg-12 mt-2 mb-5">
            <div class="row">
              <hr class="col-lg-12">
              <div class="col-lg-12">
                <p>Létrehozás</p>
              </div>
              <div class="col-lg-2">
                <small>Évszak</small>
                <b-form-select
                  id="wheel_season"
                  v-model="wheel_season"
                  :options="season_types"
                  type="text"
                  placeholder="Gumi szezon"
                />
              </div>
              <div class="col-lg-2">
                <small>Gumi márkája</small>
                <b-form-input
                  id="wheel_brand"
                  v-model="wheel_brand"
                  placeholder="Gumi márkája"
                />
              </div>
              <div class="col-lg-2">
                <small>Gumi típusa</small>
                <b-form-input
                  id="wheel_model"
                  v-model="wheel_model"
                  placeholder="Gumi típusa"
                />
              </div>
              <div class="col-lg-2">
                <small>Kerék szélessége</small>
                <b-form-input
                  id="wheel_size"
                  v-model="wheel_size"
                  type="text"
                  placeholder="Kerék szélessége"
                />
              </div>
              <div class="col-lg-2">
                <small>Profilaránya</small>
                <b-form-input
                  id="wheel_profile"
                  v-model="wheel_profile"
                  type="text"
                  placeholder="Kerék profilarány"
                />
              </div>
              <div class="col-lg-1">
                <small>Átmérő</small>
                <b-form-input
                  id="wheel_diameter"
                  v-model="wheel_diameter"
                  type="text"
                  placeholder="Kerék átmérő"
                />
              </div>
              <div class="col-lg-1">
                <small>Darabszám</small>
                <b-form-input
                  id="wheel_qty"
                  v-model="wheel_qty"
                  type="number"
                  placeholder="Darabszám"
                />
              </div>
              <div class="col-lg-12">
                <b-button
                  @click="addWheel"
                  class="w-100 mt-2 btn btn-primary"
                >
                  Létrehozás
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <hr>
          </div>
          <div class="col-lg-12 mt-2" v-for="(wheel_data, index) in wheel" :key="index">
            <div class="row">
              <div class="col-lg-2">
                <small>Évszak</small>
                <b-form-select
                  :id="'wheel_season_'+index"
                  v-model="wheel_data.season"
                  :options="season_types"
                  type="text"
                  placeholder="Évszak"
                />
              </div>
              <div class="col-lg-2">
                <small>Gumi márkája</small>
                <b-form-input
                  :id="'wheel_brand_'+index"
                  v-model="wheel_data.brand"
                  type="text"
                  placeholder="Márka"
                />
              </div>
              <div class="col-lg-2">
                <small>Gumi típusa</small>
                <b-form-input
                  :id="'wheel_model_'+index"
                  v-model="wheel_data.model"
                  type="text"
                  placeholder="Model"
                />
              </div>
              <div class="col-lg-2">
                <small>Kerék szélessége</small>
                <b-form-input
                  :id="'wheel_size_'+index"
                  v-model="wheel_data.size"
                  type="text"
                  placeholder="Kerék szélessége"
                />
              </div>
              <div class="col-lg-2">
                <small>Profilarány</small>
                <b-form-input
                  :id="'wheel_profile_'+index"
                  v-model="wheel_data.profile"
                  type="text"
                  placeholder="Profilarány"
                />
              </div>
              <div class="col-lg-1">
                <b-button
                  variant="danger"
                  class="mt-2 w-100 btn btn-primary"
                  @click="updateWheel(index)"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-1">
                <b-button
                  class="mt-2 w-100 btn btn-danger"
                  @click="removeWheel(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </div>
              <div class="col-lg-12 row justify-content-between">
                <div class="col-lg-6 row">
                  <div class="col-lg-5">
                    <small>Átmérő</small>
                    <b-form-input
                      :id="'wheel_diameter_'+index"
                      v-model="wheel_data.diameter"
                      type="text"
                      placeholder="Átmérő"
                    />
                  </div>
                  <div class="col-lg-5">
                    <small>Darabszám</small>
                    <b-form-input
                      :id="'wheel_qty_'+index"
                      v-model="wheel_data.qty"
                      type="number"
                      placeholder="Darabszám"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-3">
          </div>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    VSelect,
    BFormCheckbox,
    FormWizard,
    TabContent,
    BCard,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  /* watch: {
    work_number(val) {
      this.getDataByWorkNumber(val)
    },
  }, */
  data() {
    return {
      service_name_of_service_uniq: '',
      servicesOptions: ['Olajszűrő', 'Olaj', 'Levegőszűrő', 'Pollenszűrő', 'Üzemanyagszűrő', 'Hűtőfolyadék', 'Fékfolyadék', 'Váltóolaj', 'Hűtőfolyadék', 'Fékfolyadék'],
      service_service_name: '',
      service_billing_number: '',
      services: [],
      service_date_of_completion: '',
      service_name_of_service: [],
      service_km_at_the_time_of_service: '',
      service_service_interval: '',
      insurance_name: '',
      insurance_frequency: '',
      insurance_type: '',
      policy_number: '',
      expiry_date: '',
      price: '',
      registration_number: '',
      production_year: '',
      manufacture: '',
      variety: '',
      type: '',
      power: '',
      fuel: '',
      color: '',
      seats: '',
      technical_validity: '',
      monolit: '',
      entry_into_service: '',
      cylinder_capacity: '',
      engine_number: '',
      own_weight: '',
      total_mass: '',
      environmental_classification: '',
      monolit_options: [
        { value: 'Monolit Épszer Kft.', text: 'Monolit Épszer Kft' },
        { value: 'Monolit Profi Kft.', text: 'Monolit Profi Kft.' },
        { value: 'Egyéb', text: 'Egyéb' },
      ],
      driver: '',
      insurance: [],
      highway_sticker_buy_date: '',
      highway_sticker_expiration_date: '',
      highway_sticker_type: '',
      highway_sticker_price: '',
      highway_sticker: [],
      highway_sticker_category: '',
      today_date: new Date().toISOString().substr(0, 10),
      wheel_season: '',
      wheel_brand: '',
      wheel_model: '',
      wheel_size: '',
      employees: [],
      wheel_profile: '',
      wheel_diameter: '',
      wheel_qty: '',
      season_types: [
        { value: 'Nyári', text: 'Nyári' },
        { value: 'Téli', text: 'Téli' },
        { value: '4 évszakos', text: '4 évszakos' },
      ],
      wheel: [],
      county: '',
      countyList: [
        'Bács-Kiskun vármegye',
        'Baranya vármegye',
        'Békés vármegye',
        'Borsod-Abaúj-Zemplén vármegye',
        'Csongrád-Csanád vármegye',
        'Fejér vármegye',
        'Győr-Moson-Sopron vármegye',
        'Hajdú-Bihar vármegye',
        'Heves vármegye',
        'Jász-Nagykun-Szolnok vármegye',
        'Komárom-Esztergom vármegye',
        'Nógrád vármegye',
        'Pest vármegye',
        'Somogy vármegye',
        'Szabolcs-Szatmár-Bereg vármegye',
        'Tolna vármegye',
        'Vas vármegye',
        'Veszprém vármegye',
        'Zala vármegye',
      ],
    }
  },
  mounted() {
    this.getCars()
    this.getEmployees()
    this.getInsurance()
    this.getHighwayStickers()
    this.getService()
    this.getWheel()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
  },
  methods: {
    getEmployees() {
      axios({
        method: 'get',
        url: 'employee/list/only_name_and_id',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data
      })
    },
    getService() {
      axios({
        method: 'get',
        url: `car-service/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.services = response.data
      })
    },
    addWheel() {
      axios({
        method: 'post',
        url: `car-wheel/create/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          season: this.wheel_season,
          brand: this.wheel_brand,
          model: this.wheel_model,
          size: this.wheel_size,
          profile: this.wheel_profile,
          diameter: this.wheel_diameter,
          qty: this.wheel_qty,
        },
      }).then(() => {
        this.getWheel()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen hozzáadva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.wheel_season = ''
        this.wheel_brand = ''
        this.wheel_model = ''
        this.wheel_size = ''
        this.wheel_profile = ''
        this.wheel_diameter = ''
        this.wheel_qty = ''
      })
    },
    addService() {
      axios({
        method: 'post',
        url: `car-service/create/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          service_name: this.service_service_name,
          billing_number: this.service_billing_number,
          date_of_completion: this.service_date_of_completion,
          name_of_service: this.service_name_of_service,
          km_at_the_time_of_service: this.service_km_at_the_time_of_service,
          service_interval: this.service_service_interval,
        },
      }).then(() => {
        this.getService()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen hozzáadva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.service_service_name = ''
        this.service_billing_number = ''
        this.service_date_of_completion = ''
        this.service_name_of_service = []
        this.service_km_at_the_time_of_service = ''
        this.service_service_interval = ''
      })
    },
    removeService(index) {
      this.service_name_of_service.splice(index, 1)
    },
    addToServicesUniq() {
      if (this.service_name_of_service.indexOf(this.service_name_of_service_uniq) === -1) {
        this.service_name_of_service.push(this.service_name_of_service_uniq)
        this.service_name_of_service_uniq = ''
      }
    },
    addToServices(service) {
      if (this.service_name_of_service.indexOf(service) === -1) {
        this.service_name_of_service.push(service)
      }
    },
    getHighwayStickers() {
      axios({
        method: 'get',
        url: `car-highway-sticker/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.highway_sticker = response.data
      })
    },
    getInsurance() {
      axios({
        method: 'get',
        url: `car-insurance/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.insurance = response.data
      })
    },
    getWheel() {
      axios({
        method: 'get',
        url: `car-wheel/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.wheel = response.data
      })
    },
    removeHighwaySticker(index) {
      axios({
        method: 'delete',
        url: `car-highway-sticker/delete/${this.highway_sticker[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getHighwayStickers()
      })
    },
    removeWheel(index) {
      axios({
        method: 'delete',
        url: `car-wheel/delete/${this.wheel[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getWheel()
      })
    },
    removeInsurance(index) {
      axios({
        method: 'delete',
        url: `car-insurance/delete/${this.insurance[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getInsurance()
      })
    },
    removeServiceFull(index) {
      axios({
        method: 'delete',
        url: `car-service/delete/${this.services[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getService()
      })
    },
    updateWheel(index) {
      axios({
        method: 'put',
        url: `car-wheel/update/${this.wheel[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          season: this.wheel[index].season,
          brand: this.wheel[index].brand,
          model: this.wheel[index].model,
          size: this.wheel[index].size,
          profile: this.wheel[index].profile,
          diameter: this.wheel[index].diameter,
          qty: this.wheel[index].qty,
        },
      }).then(() => {
        this.getWheel()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    updateServiceFull(index) {
      axios({
        method: 'put',
        url: `car-service/update/${this.services[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          service_name: this.services[index].service_name,
          billing_number: this.services[index].billing_number,
          date_of_completion: this.services[index].date_of_completion,
          name_of_service: this.services[index].name_of_service,
          km_at_the_time_of_service: this.services[index].km_at_the_time_of_service,
          service_interval: this.services[index].service_interval,
        },
      }).then(() => {
        this.getHighwayStickers()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    updateHighwaySticker(index) {
      axios({
        method: 'put',
        url: `car-highway-sticker/update/${this.highway_sticker[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          buy_date: this.highway_sticker[index].buy_date,
          expiration_date: this.highway_sticker[index].expiration_date,
          type: this.highway_sticker[index].type,
          price: this.highway_sticker[index].price,
          category: this.highway_sticker[index].category,
          county: this.highway_sticker[index].county,
        },
      }).then(() => {
        this.getHighwayStickers()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    updateInsurance(index) {
      axios({
        method: 'put',
        url: `car-insurance/update/${this.insurance[index].id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          insurance_name: this.insurance[index].insurance_name,
          policy_number: this.insurance[index].policy_number,
          expiry_date: this.insurance[index].expiry_date,
          price: this.insurance[index].price,
          insurance_frequency: this.insurance[index].insurance_frequency,
          insurance_type: this.insurance[index].insurance_type,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getInsurance()
      })
    },
    addHighSticker() {
      axios({
        method: 'post',
        url: `car-highway-sticker/create/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          buy_date: this.highway_sticker_buy_date,
          expiration_date: this.highway_sticker_expiration_date,
          type: this.highway_sticker_type,
          price: this.highway_sticker_price,
          category: this.highway_sticker_category,
          county: this.highway_sticker_county,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen hozzáadva!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getHighwayStickers()
        this.highway_sticker_buy_date = ''
        this.highway_sticker_expiration_date = ''
        this.highway_sticker_type = ''
        this.highway_sticker_price = ''
        this.highway_sticker_category = ''
        this.highway_sticker_county = ''
      })
    },
    addInsurance() {
      axios({
        method: 'post',
        url: `car-insurance/create/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          insurance_name: this.insurance_name,
          policy_number: this.policy_number,
          expiry_date: this.expiry_date,
          price: this.price,
          insurance_frequency: this.insurance_frequency,
          insurance_type: this.insurance_type,
        },
      }).then(() => {
        this.getInsurance()
        this.insurance_name = ''
        this.policy_number = ''
        this.expiry_date = ''
        this.price = ''
        this.insurance_frequency = ''
        this.insurance_type = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen hozzáadva!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    getCars() {
      axios({
        method: 'get',
        url: `car/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.registration_number = res.data.registration_number
        this.production_year = res.data.production_year
        this.manufacture = res.data.manufacture
        this.variety = res.data.variety
        this.type = res.data.type
        this.power = res.data.power
        this.fuel = res.data.fuel
        this.color = res.data.color
        this.seats = res.data.seats
        this.technical_validity = res.data.technical_validity
        this.monolit = res.data.monolit
        this.driver = res.data.driver
        this.entry_into_service = res.data.entry_into_service
        this.cylinder_capacity = res.data.cylinder_capacity
        this.engine_number = res.data.engine_number
        this.own_weight = res.data.own_weight
        this.total_mass = res.data.total_mass
        this.environmental_classification = res.data.environmental_classification
      })
    },
    update() {
      axios({
        method: 'put',
        url: `car/update/${this.$route.params.id}`,
        data: {
          registration_number: this.registration_number,
          production_year: this.production_year,
          manufacture: this.manufacture,
          variety: this.variety,
          type: this.type,
          power: this.power,
          fuel: this.fuel,
          color: this.color,
          seats: this.seats,
          technical_validity: this.technical_validity,
          monolit: this.monolit,
          driver: this.driver,
          entry_into_service: this.entry_into_service,
          cylinder_capacity: this.cylinder_capacity,
          engine_number: this.engine_number,
          own_weight: this.own_weight,
          total_mass: this.total_mass,
          environmental_classification: this.environmental_classification,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push('/cars/list')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A rendszám kötelező mező!',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
